import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/layout'
import Login from '@/views/login'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/login', component: Login },
    { path: '/register', component: (resolve) => require(['@/views/login/register'], resolve) },
    {
      path: '/',
      component: Layout,
      redirect: '/index',
      children: [
        { path: 'index', component: (resolve) => require(['@/views/index/index'], resolve) }, // 首页
        { path: 'sele', component: (resolve) => require(['@/views/index/sele'], resolve) }, // 销课记录
        { path: 'student/list', component: (resolve) => require(['@/views/index/student/list'], resolve) }, // 学员信息列表
        { path: 'student/info', component: (resolve) => require(['@/views/index/student/info'], resolve) }, // 学员个人信息
        { path: 'work', component: (resolve) => require(['@/views/index/work'], resolve) }, // 教学练习
        { path: 'vocabulary/exam', component: (resolve) => require(['@/views/index/vocabulary/exam'], resolve) }, // 词汇测试首页
        { path: 'vocabulary/exam/info', component: (resolve) => require(['@/views/index/vocabulary/examInfo'], resolve) }, // 词汇测试
        { path: 'myzoe', component: (resolve) => require(['@/views/index/myzoe'], resolve) }, // 个人中心
        { path: 'work/studytype', component: (resolve) => require(['@/views/index/study/type'], resolve) }, // 课程选择
        { path: 'work/studyattend', component: (resolve) => require(['@/views/index/study/attend'], resolve) }, // 上课
        { path: 'work/test', component: (resolve) => require(['@/views/index/study/test'], resolve) },
        { path: 'review', component: (resolve) => require(['@/views/index/study/review'], resolve) }, // 21天抗遗忘
        { path: 'remember', component: (resolve) => require(['@/views/index/study/remember'], resolve) }, // 单词识记
        { path: 'cutbox', component: (resolve) => require(['@/views/index/study/cutbox'], resolve) }, // 剪纸盒
        { path: 'study/time', component: (resolve) => require(['@/views/index/study/studyTime'], resolve) }, // 抗遗忘时间
        { path: 'test/mywords', component: (resolve) => require(['@/views/index/test/mywords'], resolve) }, // 我的词库
        { path: 'rest/index', component: (resolve) => require(['@/views/index/rest/index'], resolve) }, // 休息时间申请
        { path: 'vocabulary/exam/list', component: (resolve) => require(['@/views/index/vocabulary/examList'], resolve) }, // 词汇测试记录
        { path: 'message', component: (resolve) => require(['@/views/index/message/index'], resolve) }, //消息列表
        { path: 'message/info', component: (resolve) => require(['@/views/index/message/info'], resolve) }, //消息详情
        { path: 'agreement/info', component: (resolve) => require(['@/views/index/agreement/info'], resolve) }, //协议详情
        { path: 'review/create', component: (resolve) => require(['@/views/index/review/words'], resolve) }, //自主创建抗遗忘

        //二期 阅读理解
        { path: 'reading/index', component: (resolve) => require(['@/views/reading/index'], resolve) }, //阅读理解 选择课程
        { path: 'reading/chapter', component: (resolve) => require(['@/views/reading/chapter'], resolve) }, //词串
        { path: 'reading/level', component: (resolve) => require(['@/views/reading/level'], resolve) }, //选择关卡
        { path: 'reading/analysis', component: (resolve) => require(['@/views/reading/analysis'], resolve) }, //答题分析
        { path: 'reading/info', component: (resolve) => require(['@/views/reading/info'], resolve) }, //阅读详情
        { path: 'reading/answer', component: (resolve) => require(['@/views/reading/answer'], resolve) }, //做题
        { path: 'reading/remember', component: (resolve) => require(['@/views/reading/remember'], resolve) }, //识记
        { path: 'reading/picture', component: (resolve) => require(['@/views/reading/picture'], resolve) }, //点读
        { path: 'reading/tuwenanswer', component: (resolve) => require(['@/views/reading/tuwenanswer'], resolve) }, //图文的答案
      ]
    },
    {
      path: '/screen',
      component: (resolve) => require(['@/views/screen/index'], resolve),
      children:[
        { path: '/screen/work', component: (resolve) => require(['@/views/index/work'], resolve) }, // 教学练习
        { path: '/screen/vocabulary/exam', component: (resolve) => require(['@/views/index/vocabulary/exam'], resolve) }, // 词汇测试首页
        { path: '/screen/vocabulary/exam/info', component: (resolve) => require(['@/views/index/vocabulary/examInfo'], resolve) }, // 词汇测试
        { path: '/screen/work/studytype', component: (resolve) => require(['@/views/index/study/type'], resolve) }, // 课程选择
        { path: '/screen/swork/studyattend', component: (resolve) => require(['@/views/index/study/attend'], resolve) }, // 上课
        { path: '/screen/work/test', component: (resolve) => require(['@/views/index/study/test'], resolve) },

      ]
    }
  ]
})

// 只有登录页可以访问，其他都要拦截
router.beforeEach((to, from, next) => {
  // console.log('to',to, 'form')
  // const isfull=store.state?.user?.isFull
  // console.log('is',isfull)

  const token = store.state?.user?.token
  if (to.path == '/register') {
    next()
    return
  }
  // if(isfull){
  //   to ='/screen'+to.fullPath
  //   next()
  //   return
  // }
  if (to.path !== '/login' && !token) {
    next('/login')
  } else {
    next()
  }
})

export default router
