<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default{
 
}
</script>

<style lang="scss">

*{ 
 -webkit-touch-callout:none; /*系统默认菜单被禁用*/
 -webkit-user-select:none; /*webkit浏览器*/
 -khtml-user-select:none; /*早期浏览器*/
 -moz-user-select:none;/*火狐*/
 -ms-user-select:none; /*IE10*/
 user-select:none; 
} 
html,body{
  min-width: 1200px;
  overflow: auto;
}
.tagword{
    background-color: #0B9AFF;
    background:#0B9AFF;
    color: #fff;
}
</style>
