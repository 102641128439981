

export default {
    inserted(el, binding) {
        let isError = false
        el.onerror = function () {
            if (isError) return
            isError = true
            el.src = binding.value
        }
    }
}
