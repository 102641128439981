<template>
  <div ref="bearDialog" v-if="dialogVisible" class="bear_dialog_box" @click.stop="closeBefore(3)">
      <div 
          :class="['bear_dialog_content transition', { bearfull: isFull }]"
          :style="{ width: width ? width : '', borderRadius: isFull ? '0px' : '', height: height ? height : '', overflowY: isTitle ? '':'none',}" 
          @click.stop
          ref="bearDialogContent"
      >
          <!-- 顶部标题一级操作的按钮 -->
          <div class="bear_dialog_content_top" v-if="isTitle">
              <!-- 顶部插槽位于标题左边 -->
              <slot name="topControl"></slot>
              <!-- 标题 -->
              <div div v-if="title">{{ title }}</div>

              <div v-else-if="titleDiy">
                  <slot name="titleDiy"></slot>
              </div>
              
              <!-- 按钮部分 -->
              <div>
                  <i v-if="!isFull && isShowFull" class="dialog_title_control el-icon-full-screen" title="全屏" @click="fullBefore(true)"></i>
                  <i v-if="isFull && isShowFull" class="dialog_title_control el-icon-copy-document" title="还原" @click="fullBefore(false)"></i>
                  <i class="dialog_title_control el-icon-close" title="关闭" @click="closeBefore(3)"></i>
              </div>
          </div>

          <!-- 中间需要传入的组件 -->
          <div 
              class="bear_dialog_content_center"
              :style="{ 'height': isUserFooter ? 'calc(100% - 13.5rem)' : 'calc(100% - 5.5rem)', 'padding': padding }"
            >
              <slot v-if="isKeepAlive ? isKeepAlive : (!isKeepAlive && dialogVisible)"></slot>
          </div>

          <!-- 底部的取消和确定按钮 -->
          <div v-if="isUserFooter" slot="footer" class="bear_dialog_content_bottom dialog-footer">
              <slot name="footerSlot">
                  <el-button style="width: 180px;" size="" @click="closeBefore(2)" v-if="isShowCancel" plain>取 消</el-button>
                  <el-button style="width: 180px;" size="" type="primary" @click="closeBefore(1)" v-if="isShowConfirm">确 定</el-button>
              </slot>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'bear_dialogVue',
  props: {
    //padding
    padding: {
      type: String,
      default: () => "2rem",
    },
    // 弹窗标题
    title: {
      type: String,
      default: () => "",
    },
    //是否显示标题栏
    isTitle:{
      type:Boolean,
      default:()=>true,
    },
    // 弹窗宽
    width: {
      type: String,
      default: () => "50%",
    },
    // 弹窗高
    height: {
      type: String,
      default: () => "80vh",
    },
    marginTop:{
      type: String,
      default: () => "10vh",
    },
    // 是否使用弹窗自带的脚注
    isUserFooter: {
      type: Boolean,
      default: () => true,
    },
    // 是否使用标题自定义
    titleDiy: {
      type: Boolean,
      default: () => true,
    },
    // (｡･∀･)ﾉﾞ
    // ～(￣▽￣～)朝这里看(～￣▽￣)～
    // 弹窗关闭时将会触发的函数   ┗|｀O′|┛ 嗷~~,看我看我看我！！！！！！，这个是一个异步函数，所以你传入的函数需要有返回值，return true：表示可以关闭弹窗，return false:表示不能关闭弹窗（也就是说你可以通过返回值来限制当用户点击了关闭时的关闭事件）,当然你也可以不写返回值，当没有返回值的时候，系统默认直接关闭弹窗。o(〃'▽'〃)o 好了我说完了，你听懂了么
    // ～(￣▽￣～)朝这里看(～￣▽￣)～
    closeCallBack: {
      type: Function,
      default: () => {
        return new Promise((res, req) => {
          res(true);
        });
      },
    },
    // 弹窗全屏或者还原时触发  和closeCallBack原理一致
    fullCallBack: {
      type: Function,
      default: () => {
        return new Promise((res, req) => {
          res(true);
        });
      },
    },
    // 再次打开弹窗是否需要保存之前的结果
    isKeepAlive: {
      type: Boolean,
      default: () => true,
    },
    //是否显示全屏按钮
    isShowFull: {
      type: Boolean,
      default: () => false,
    },
    //是否显示取消按钮
    isShowCancel: {
      type: Boolean,
      default: () => true,
    },
    //是否显示确定按钮
    isShowConfirm: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      // 是否打开弹窗
      dialogVisible: false,
      // 是否全屏
      isFull: false,
    };
  },
  methods: {
    // 开启弹窗
    openDialog() {
      this.dialogVisible = true;
    },
    // 关闭弹窗
    closeDialog() {
      this.closeCallBack(4)
      this.dialogVisible = false;
    },
    // 关闭时触发
    /**
     *
     * @param {number} closeType 关闭弹窗的类型
     * 1.点击了确定按钮关闭
     * 2.点击了取消按钮关闭
     * 3.点击了右上角的x关闭
     * 4.通过手动触发closeDialog关闭
     */
    async closeBefore(closeType) {
      let _res = await this.closeCallBack(closeType)
      if (_res || _res == undefined) this.dialogVisible = false
    },
    // 全屏还原时触发
    /**
     * 
     * @param {boolean} isfull 
     * true 代表将要全屏
     * false 代表将要还原
     */
    async fullBefore(isfull) {
      let _res = await this.fullCallBack(isfull)
      if (_res || _res == undefined) this.isFull = isfull
    }
  },

    watch: {
        dialogVisible(show) {
            setTimeout(() => {
                if (show) {
                    this.$refs.bearDialogContent.style.transform = "scale(1)"
                    this.$refs.bearDialogContent.style.opacity = 1
                }
            }, 0)
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/public.scss";

.bear_dialog_box {
  position: fixed;
  z-index: 1999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .7);
  overflow: hidden;
  box-sizing: border-box;
  display: flex;

  .bearfull {
    width: 100% !important;
    height: 100% !important;
    margin: 0 auto !important;
    max-width: 100% !important;
  }

  .bear_dialog_content {
    opacity: 0;
    transform: scale(0.5);
    margin: auto;
    background: #ffffff;
    overflow: hidden;
    border-radius: 8px;
    max-width: 1320px;
    background-image: url(@/assets/img/bj_1.png);
    background-size: 100% 100%;
    .bear_dialog_content_center {
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 1px;
    }
    /* 滑块部分 */
    &::-webkit-scrollbar-thumb { 
        border-radius: 5px;
        background-color:#0B9AFF;
    }
    /* 轨道部分 */
    &::-webkit-scrollbar-track { 
    //   box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: #fff;
        border-radius: 5px;
    }
    }

    &_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // border-bottom: 1px solid #e8e9f0;
      height: 5.5rem;
      padding: 2rem 2rem 1rem;
      font-size: 2.6rem;
      @extend .text_1;

      .dialog_title_control {
        cursor: pointer;
        padding-left: 7px;
        font-weight: bolder;
      }

      .el-icon-close {
        transition: all 0.7s;
      }

      .el-icon-close:hover {
        color: #de333f;
      }
    }

    &_bottom {
      display: flex;
      justify-content: center;
      padding: 0 0 4rem;
    }
  }
}
</style>
