<template>
  <div class="c-footer">
    <div class="flex-center">
      <div class="cur" @click="jumpPage('use_agreement_copyright')">使用协议和版权申明</div>
      <div style="margin:0 10px;">丨</div>
      <div class="cur" @click="jumpPage('user_privacy_agreement')">用户隐私保护指引</div>
      <div style="margin:0 10px;">丨</div>
      <div class="cur" @click="jumpPage('children_privacy_agreement')">儿童隐私政策</div>
      <div style="margin:0 10px;">丨</div>
      <div>公司全称：{{ componyName }}</div>
      <div class="cur" style="margin:0 10px;" @click="jumpBeian">{{ websiteBeian }}</div>
    </div>
  </div>
</template>

<script>
import { config } from '@/api/config.js'
export default {
  data() {
    return {
        componyName: '',
        websiteBeian: ''
    };
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
        let param = [
            'company_name',
            'website_beian'
          ]
        config({ key: param }).then(res => {
            if (res.error === 0) {
                this.componyName = res.data.company_name
                this.websiteBeian = res.data.website_beian
            }
        })
    },
    jumpPage(val) {
        this.$router.push({ path: '/agreement/info', query: { key: val } })
    },
    jumpBeian() {
        let url = 'https://beian.miit.gov.cn'
        window.open(url)
    }
  }

};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins.scss";
.c-footer {
  height: 100%;
  // background: #00000f;
  text-align: center;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #aaa;
}

.flex-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
</style>
