<template>
    <!-- :style="width:calc(100% - menuType )" -->
  <div class="header_cont" :style="updateWidth()">
    <div class="left cur" @click="jumpIndex">
      <img class="login_img" src="@/assets/img/logo1.png"/>
      <!-- <span class="title_name">途正智能英语</span>  -->
    </div>
    <div class="right flex_center">
      <el-tooltip class="" effect="dark" content="全屏" placement="bottom">
      <div class="item">
        <img class="cur sceen" src="@/assets/img/sceen.png" alt="" srcset="" @click="pullFn">
      </div>
      </el-tooltip>
      
      <el-popover
          placement="bottom"
          width=""
          trigger="hover">
          <img slot="reference" class="cur phone" src="@/assets/img/phone.png" alt="" srcset="">
          <div class="code_cont flex_center_center">
            <img class="code_img" src="@/assets/img/wxqrcode.jpg" alt="">
            <p class="code_text">学生端</p>
          </div>
      </el-popover>
      <el-tooltip class="" effect="dark" content="公告" placement="bottom">
        <div class="item">
          <el-badge :value="unreadNumber" class="" :hidden="unreadNumber == 0">
            <img class="cur infom" src="@/assets/img/infom.png" alt="" srcset="" @click="noticeFn">
          </el-badge>
        </div>
      </el-tooltip>
      
      <el-popover placement="bottom" trigger="hover" popper-class="poper_info">
          <div class="poper_cont">
              <p class="text1 cur" v-if="userInfo" @click="goUserCenter">{{ userInfo.name }}</p>
              <p class="text2 cur" @click="wordsFn">我的词库</p>
              <p class="text3 cur" @click="loginOutFun()">退出登录</p>
          </div>

          <div class="user cur"  slot="reference">
              <el-avatar
                  :size="36"
                  fit="fill"
                  :src="userInfo && userInfo.portrait ? userInfo.portrait.url : ''"
              />
              
              <i class="el-icon-arrow-down" size="16" style="margin-left: 5px;"></i>
          </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { loginOut } from '@/api/auth.js'
import {mapState} from 'vuex';
import { messageUnreadCount } from '@/api/message.js'
export default {
  name: "Header",
  components: {},
  props: {},
  data() {
    return {
       showCockpit: false,
       unreadNumber: 0,
    };
  },
  computed: {
      ...mapState({
          userInfo: state => state.user.userInfo,
          menuSate: state => state.user.menuState,
          fullState:state => state.user.fullState
      }),
   
  },
  mounted() {
    this.getUnReadNumber()
  },
  methods: {
    pullFn(){
      this.$store.commit('user/setFull',true)
      this.$store.commit('user/setfullState',0)
    },
    updateWidth() {
      let w = "100%";
      let num = this.menuSate?'30rem':'18rem'
      return { width: `calc(${w} - ${num})` };
    },
    loginOutFun() {
       loginOut().then(res => {
            if (res.error == 0) {
              this.$store.commit('user/logout')
              this.$router.push('/login')
              localStorage.removeItem('tips')
              sessionStorage.setItem('change',false)
            }else{
              this.$message.error(res.message)
            }
        })
    },
    //获取未读消息数
    getUnReadNumber() {
        messageUnreadCount().then(res => {
          if (res.error == 0) {
              this.unreadNumber = res.data
              console.log('消息数', this.unreadNumber)
          }
        })
    },
    noticeFn(){
      this.$router.push('/message')
    },
    wordsFn(){
      this.$router.push('/test/mywords')
    },
    jumpIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    goUserCenter() {
      if (this.$route.fullPath == "/myzoe") {
          this.$message.error("现在就是个人中心哦~");
          return
      }
      this.$router.push({
        path: "/myzoe",
      });
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/mixins.scss";

.header_cont {
  position: fixed;
  width: calc(100% - 30rem);
  padding: 0.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  box-sizing: border-box;
  padding: 1.5rem 8rem 1.5rem 2.4rem;
  .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    // img {
    //   height: 0.56rem;
    // }
   .login_img{
    width: 15.58rem;
    height: 6.4rem;
   }
    .title_name {
      font-weight: bold;
      color: #fff;
      font-size: 2.4rem;
      color: #1A1A1A;
      margin-left: 3px;
    }
  }
  .right {
  //  width: 16rem;
   .phone,.item{
    margin-right: 3.2rem;
   }
   .phone,.infom,.sceen{
     width: 2.8rem;
     height: 2.8rem;
   }
   .phone:hover{
    content: url(@/assets/img/phone_1.png);
   }
   .infom:hover{
    content: url(@/assets/img/infom_1.png);
   }
   .sceen:hover{
    content: url(@/assets/img/sceen_1.png);
   }
   .user{
    display: flex;
    align-items: center;
    font-size: 4px;
    &:hover{
      color: #0B9AFF;
    }
   }
  }
  .guide-btn {
    font-size: 0.18rem;
    padding: 0.09rem 0.15rem;
  }
}
.code_cont{
  // padding: 10px;
  box-sizing: border-box;
  .code_img{
    width: 14.8rem;
    height: 14.8rem;
  }
  .code_text{
    font-size: 1.4rem;
    color: #626780;
    margin-top: 1.5rem;
  }
}
.poper_cont{
  // width: 124px;
  text-align: center;
  color: #3A3F5E;
  font-size: 1.8rem;
  
  .text1{
    padding-bottom: 12px;
    &:hover{
      color: #0B9AFF;
    }
  }
  .text2{
    padding: 12px 0;
    border-top: 1px solid #F0F2F4;
    border-bottom: 1px solid #F0F2F4;
    &:hover{
      color: #0B9AFF;
    }
  }
  .text3{
    padding-top: 12px;
    &:hover{
      color: #0B9AFF;
    }
  }
}
</style>
