import Tooltip from './tooltip';

/* istanbul ignore next */
Tooltip.install = function(Vue) {
    Vue.component(Tooltip.name, Tooltip);
};

const install = function(Vue) {
    Vue.component(Tooltip.name, Tooltip);
};
    
    /* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
}

export default Tooltip;
