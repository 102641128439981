<template>
    <div class="empty_cont flex_center_center">
        <img v-if="type==0" class="img" src="@/assets/img/empty.png" />
        <img v-else-if="type==1" class="img1" src="@/assets/img/empty1.png" />
        <img v-else-if="type==2" class="img2" src="@/assets/img/empty2.png" />
        <p class="text">{{text}}~</p>
    </div>
</template>

<script>
    export default {
        props:{
            type:{
                type:String,
                default:'0'
            },
            text:{
                type:String,
                default:''
            }
            
        }
    }
</script>

<style lang="scss" scoped>

.empty_cont{
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    
    .img{width: 12.9rem;height: 10.7rem}
    // .img{width: 209px;height: 154px}
    .img1{width: 12.8rem;height: 10.6rem}
    .img2{width: 12.8rem;height: 10.7rem}

    .text{text-align: center;font-size: 1.8rem;color: #626780;margin-top: 1rem}
}

</style>