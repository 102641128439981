/* eslint-disable vue/multi-word-component-names */
// import 'babel-polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/element'
import '@/styles/index.scss'
import empty from '@/views/components/empty.vue'
import pagination from '@/components/pagination'

import imgError from '@/utils/imgError'
import overflow from '@/utils/overflow'
import '@/utils/rem.js'
import Element from 'element-ui';
Vue.use(Element);

Vue.config.productionTip = false
Vue.component('empty', empty)
Vue.component('pagination', pagination)
Vue.directive('imgError', imgError)
Vue.directive('overflow', overflow)


new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
