<template>
    <!-- <div class="page_cont"> -->
        <el-pagination
            background
            @current-change="paginationChange"
            :current-page="currentPage"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
            >
        </el-pagination>
    <!-- </div> -->
</template>

<script>
export default {
    name: 'xPagination',
    components: {
	},
    props: {
        // 总条数
        total: {
            type: Number,
            default: 100,
        },
        // 当前页码
        currentPage: {
            type: Number,
            default: 1,
        },
        // 每页几条数据
        pageSize: {
            type: Number,
            default: 10,
        },
    },
    emits: ['size-change','current-change'],
    data() {
        return {
            pageSizeItems: [8, 15, 30]
		}
    },
    mounted() {
    },
    methods: {
        paginationChange(page) {
            this.$emit('current-change', page)
		},
		handlePageSizeChange(pageSize) {
            this.$emit('size-change', pageSize)
		},
    },
};
</script>

<style scoped lang="scss">

.page_cont{
    margin-top: 32px;
}

</style>