import request from '@/utils/request'

// 获取消息列表
export const messageList = (params) => {
    return request.get('/message/list', { params })
}

// 获取消息详情
export const messageDetail = (params) => {
    return request.get('/message', { params })
}

// 获取未读消息数量
export const messageUnreadCount = (params) => {
    return request.get('/message/unread-message-numbers', { params })
}

// 消息已读
export const messageViewed = (params) => {
    return request.put('/message/viewed', params)
}


// 删除消息
export const deleteMessage = (params) => {
    return request.delete('/message', { params })
}