import request from '@/utils/request'

// 账号密码登录
export const login = (params) => {
  return request.get('/auth', { params })
}

// 验证码登录
export const loginSmsCode = (params) => {
  return request.get('/auth/sms-code', { params })
}

//退出登录
export const loginOut = (params) => {
  return request.put('/auth/login-out', params)
}

//获取短信验证码
export const smsCode = (params) => {
  return request.post('/sms/verify-code', params)
}

//验证码修改密码
export const changePasswordBySms = (params) => {
  return request.put('/auth/password-by-sms-code', params)
}

//修改密码
export const changePassword = (params) => {
  return request.put('/auth/password', params)
}

//注册
export const register = (params) => {
  return request.post('/register', params)
}

// 获取用户信息
export const getUser = () => {
  return request.get('/auth/user')
}
