/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
	const valid_map = ['admin', 'editor']
	return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
	const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
	return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
	const reg = /^[a-z]+$/
	return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
	const reg = /^[A-Z]+$/
	return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
	const reg = /^[A-Za-z]+$/
	return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
	const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
	if (typeof str === 'string' || str instanceof String) {
		return true
	}
	return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
	if (typeof Array.isArray === 'undefined') {
		return Object.prototype.toString.call(arg) === '[object Array]'
	}
	return Array.isArray(arg)
}

/**
 * 验证手机号码
 * @param {String} arg 
 * @returns {Boolean}
 */
export function checkPhone(arg) {
	if (arg) {
		const reg = /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/;
		if (reg.test(arg)) {
			return true
		} else {
			return false
		}
	}

	return true
};

/**
 * 验证手机号码
 * @param {String} arg 
 * @returns {Boolean}
 */
export function checkMobile(rule, value, callback) {
	let myreg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
	if (rule.required && !value) {
		callback(new Error(rule.message || '请输入手机号码'))
	} else if (value && !myreg.test(value)) {
		callback(new Error('手机号码格式不正确'))
	} else {
		callback()
	}
};

/**
 * 验证联系方式(座机|手机)
 * @param {String} arg 
 * @returns {Boolean}
 */
export function checkContactTel(rule, value, callback) {
	let mobRegNeg = /^(13[0-9]|14[5|7]|147|15[0-3,5-9]|166|17[0-9]|18[0-9]|19[1|8|9])\d{8}$/
	let telRegNeg = /^([0-9]{3,4}-)?[0-9]{7,8}$/

	if (rule.required && !value) {
		callback(new Error('请输入电话号码'))
	} else if (value && (!mobRegNeg.test(value) && !telRegNeg.test(value))) {
		callback(new Error('电话号码格式不正确'))
	} else {
		callback()
	}
};

/**
 * 验证只能是整数
 * @param {String} arg 
 * @returns {Boolean}
 */
export function checkInteger(rule, value, callback) {
	let myreg = /^(-|[0-9])\d*$/;
	if (rule.required && !value) {
		callback(new Error(rule.message || '请输入'))
	} else if (value && !myreg.exec(value)) {
		callback(new Error('只能输入整数'))
	} else {
		callback()
	}
}

/**
 * 验证只能是数字
 * @param {String} arg 
 * @returns {Boolean}
 */
 export function checkNumeric(rule, value, callback) {
	let myreg = /^0\.([0-9]|\d[0-9])$|^[0-9]\d{0,8}\.\d{0,2}$|^[0-9]\d{0,8}$/
	if (rule.required && !value) {
		callback(new Error(rule.message || '请输入'))
	} else if (value && !myreg.exec(value)) {
		callback(new Error('请输入正确的数值'))
	} else {
		callback()
	}
}

/**
 * 验证只能是URL链接
 * @param {object} rule
 * @param {string} value
 * @returns {mixed}
 */
 export function checkUrlItems(rule, value, callback) {
	value = value.split(/[\s\n]/).filter(row => row !== '' && row !== null)
	if (rule.required && !value.length) {
		callback(new Error(rule.message || '请输入'))
	}

	let myreg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g
	value.forEach(item => {
		if (!item.match(myreg)) {
			callback(new Error('存在无效的URL链接!'))
		}
	})

	callback()
}
