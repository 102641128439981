<template>
    <div class="cont flex_center_center">
        <div class="phone_cont">
            <img class="img1" :src="userInfo && userInfo.portrait ? userInfo.portrait.url : ''" v-img-error="require('@/assets/img/avatar.png')" v-if="userInfo && userInfo.portrait" />
            <img class="img2" :src="userInfo && userInfo.title ? userInfo.title.icon : ''" v-img-error="require('@/assets/img/avatar.png')" v-if="userInfo && userInfo.title" />
        </div>
        <p v-if="type" class="name_cont">{{ userInfo ? userInfo.name : '' }}<span class="jiao">教练</span></p>
        <p v-if="type" class="add_text">{{ userInfo && userInfo.agent ? userInfo.agent.name : '' }}</p>
    </div>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: 'info',
    props:{
        type:{
            type:Boolean,
            default:true,
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo
        })
    },
}
</script>

<style lang="scss" scoped>
.cont{
    width: 100%;
    .name_cont{
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0em;
        color: #1A1A1A;
        margin-top: 6px;
        font-weight: normal;
        .jiao{
            border-radius: 4px;
            height: 2rem;
            line-height: 2rem;
            text-align: center;
            padding: 0 4px;
            background: #0B9AFF;
            font-size: 1.4rem;
            color: #fff;
            margin-left: 1.4rem;
        }
    }
    .add_text{
        font-family: MicrosoftYaHei;
        font-size: 1.8rem;
        text-align: center;
        letter-spacing: 0em;
        color: #626780;
        margin-top: 1.2rem;
    }
}
    .phone_cont{
        position: relative;
        width: 9.8rem;
        height: 9.8rem;
        .img1{
            width: 9.8rem;
            height: 9.8rem;
            border-radius: 50%;
        }
        .img2{
            width: 2rem;
            height: 2rem;
            position: absolute;
            right: .577rem;
            bottom: .777rem;
        }
    }
</style>