





<template>
    <div class="container flex flex-column h_full justify-between" ref="boxfull" :class="ischange?'changebj':''" :style="[{padding: Screen ? '8.4rem 11.4rem 0' : '2.0rem 2.0rem 0'}]" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <!-- @mouseenter="handlemouseenter" -->
        <el-tooltip class="" effect="dark" content="退出全屏" placement="bottom" :hide-after="500">
            <div class="pull_cont cur">
                <img v-if="Screen" @click="shoudong" class="pull_img" :src="ischange?background:background1" />
            </div>
        </el-tooltip>
        <!-- <p class="pull_text">退出全屏</p> -->
        <div class="">
            <slot name="title"></slot>
        </div>
        <div class="cont_part flex_column_between h_full" :class="ischange?'tans':''" v-if="type==0">
            <slot></slot>
        </div>
        <div class="cont_part1 flex_column_between h_gull" v-else>
            <slot></slot>
        </div>
        <!-- <div class="cont_footer" v-if="!isFullscreen||isFullscreen&&!ischange"> -->
        <div class="cont_footer" v-if="!ischange">
            <Footer></Footer>
        </div>
        <!--  -->
    </div>
</template>

<script>
import screenfull from 'screenfull'
import Footer from '@/views/layout/Footer.vue'

    export default {
        name:'bear_content',
        components:{
            Footer
        },
        props:{
            // padding:{
            //     type:String,
            //     default:'20rem 20rem 0'
            // },
            // type:{
            //     type:String,
            //     default:'0'
            // }
        },
        data() {
            return {
                key: '',
                padding:'2rem 2rem 0',
                isScreen:false,//显示退出全屏按钮
                isFullscreen:false,//是否全屏
                background:require('@/assets/img/quit_2.png'),
                background1:require('@/assets/img/quit.png'),
                ischange:false,//是否为上课页
                type:0
            }
        },
        computed:{
            Screen(){
                return this.$store.state.user.isFull
            },
            // ischange(){
            //     return JSON.parse(sessionStorage.getItem('change'))|| false
            // }
        },
        watch:{
            Screen(newObj,oldObj){
                this.handlemouseenter(newObj)
            },
            $route: {
                handler: function(val, oldVal){
                    if(val.path=='/sele'){
                        this.type=1
                    }else{
                        this.type=0
                    }
                    if(val.path=='/work/studytype'||val.path=='/work/studyattend'||val.path=='/work/test'||val.path=='/reading/remember'){
                        this.ischange=true
                        sessionStorage.setItem('change',true)
                        this.padding="2rem 11.4rem 0"

                    }else{
                        this.ischange=false
                        sessionStorage.setItem('change',false)
                        if(this.isFullscreen){
                            this.padding="8.4rem 11.4rem 0"
                        }else{
                            this.padding="2.0rem 2.0rem 0"
                        }

                    }
                },
                deep: true
            }
        },
        mounted(){
            console.log(11111111, this.$store.state.user.isFull)
            if(JSON.parse(sessionStorage.getItem('change')) ){
                this.ischange=true
            }
            document.addEventListener('fullscreenchange', this.handleFullscreenChange)
        },
        beforeDestroy() {
            document.removeEventListener('fullscreenchange', this.handleFullscreenChange)
        },
        methods:{
            shoudong(){
                this.$store.commit('user/setFull', false)
                screenfull.toggle(this.$refs.boxfull);
            },
            handleFullscreenChange() {
                if (!document.fullscreenElement) {
                    console.log('退出了全屏模式');
                    this.$store.commit('user/setFull', false)
                    console.log(this.Screen)
                }
            },
            handlemouseenter(bool){
                this.$store.commit('user/setFull', bool)
                if(bool){
                    this.$nextTick(()=>{
                        screenfull.toggle(this.$refs.boxfull);  
                    })
                }
                
                // this.toggleFullScreen()
            },
            // toggleFullScreen() {
            //     console.log('测试：', this.ischange)
            //     if (screenfull.isEnabled) {
            //         // isFullscreen&&!ischange
            //         if(!this.ischange){
                        
            //             this.padding="8.4rem 11.4rem 0"
            //             console.log(this.padding)
            //         }else{
            //             this.padding="2rem 11.4rem 0"
            //             console.log(this.padding)
            //         }
            //         this.isScreen=true
            //         this.$nextTick(()=>{
            //             screenfull.toggle(this.$refs.boxfull);
            //         })
            //     }
            // },
            // resizeListener() {
            //     const that = this
            //     window.addEventListener('resize', function () {
            //         let full=that.isFullScreenFn()
            //         console.log(111111, full)
            //         if(!full){
            //             that.isScreen=false
            //             that.padding='2.0rem 2.0rem 0'
            //         }
            //     })
            // },
            //   // 监听是否全屏状态
            // onresize(event) {
            //     // 利用屏幕分辨率和window对象的内高度来判断兼容IE
            //     try{
            //         let winFlag = window.innerHeight === window.screen.height
            //         // 利用window全屏标识来判断 -- IE无效
            //         let isFull = window.fullScreen || document.webkitIsFullScreen

            //         if (isFull === undefined) {
            //             this.isFullscreen = winFlag
            //         } else {
            //             this.isFullscreen = winFlag || isFull
            //         }
            //         // console.log('全屏',);  // true全屏   false不是全屏
            //         this.isFullscreen=winFlag
            //         if(winFlag){
            //             this.isScreen=true
            //         }
            //         // console.log('onresize',this.$store.state.user.isFull)
            //         if(!winFlag){
            //             this.$store.commit('user/setFull',false)
            //             this.$store.commit('user/setmenu',false)
            //             this.$store.commit('user/setfullState',1)
            //         }else{
                        
            //         }
            //     }catch(e){
            //         console.log(e)
            //     }

            // },
            handleMouseEnter() {
                // console.log('鼠标移入');
            },
            handleMouseLeave() {
                // console.log('鼠标移出');
            }
        }
    }
</script>

<style lang="scss" scoped>
.h_full{
    height: calc(100% - 8.4rem);
    // height: calc(100% - 2rem);
}
.h_gull{
    height: calc(100% - 8.4rem);
}
.h_full1{
    height: 100% ;

}
.container{
    height: 100%;
    overflow: hidden;
    background-image: url(@/assets/img/bj.png);
    // background-image: url(@/assets/img/bj_6.png);
    background-size: 100% 100%;
    position: relative;
    .pull_cont{
        position: absolute;
        // right: 11.4rem;
        right: 4.2rem;
        top: 4.2rem;
    }
    .pull_img{
        width: 2.8rem;
        height: 2.8rem;
        &:hover{
            content: url(@/assets/img/quit_1.png);
        }
    }
    .pull_text{
        position: absolute;
        top: 2rem;
        left: 11.4rem;
    }
    .cont_part{
        width: 100%;
        background: #fff;
        border-radius: 2.0rem;
    }
    .cont_part1{
        width: 100%;
        // height: calc(100% - 22.8rem);
        height: calc(100% - 8.4rem);

        background: #fff;
        border-radius: 2.0rem;
    }
    .cont_footer{
        height: 8.4rem;
    }
}
.el-footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaa;
    font-size: 1.4rem;
  }
.changebj{
    // background-image: url(@/assets/img/bj_6.png);
    background-image: url(@/assets/img/bj_8.png);
    // background: #CCD6E5;
}
.tans{
    background: transparent !important;
    height: 100%;
}
</style>
