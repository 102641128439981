<template>
  <el-container class="layout-page">
      <el-aside :width="menuType?'30rem':'18rem'">
          <info :type="menuType" />
          <el-menu
            router
            :default-active="$route.path"
            background-color="#fff"
            text-color="#626780"
            active-text-color="#0B9AFF"
            @select="handleSelect"
          >
              <el-menu-item >
                  <div class="path_cont flex_center_between" v-if="menuType" @click="foldFn">
                      <div>
                          <img style="width: 2.4rem;height: 2.4rem;" src="@/assets/menu/fold.png"/>
                          <span style="margin-left: 1rem">折叠菜单</span>
                      </div>

                      <div style="margin-right: 5px;">
                          <img style="width: 2.4rem;height: 2.4rem;" src="@/assets/menu/fold_1.png" />
                      </div>
                  </div>
                  
                  <div v-else class="fold_box flex_c_c" >
                      <img  style="width: 2.4rem;height: 2.4rem;" src="@/assets/menu/fold_2.png"  @click="foldFn" />
                  </div>
              </el-menu-item>

              <el-menu-item v-for="item in menuList" :index="item.path" :key="item.id">
                  <div v-if="menuType" class="path_cont flex_center_between" :class="keyPath==item.path?'pathbj':''">
                      <div>
                          <img style="width: 2.4rem;height: 2.4rem;" v-if="keyPath==item.path" :src="item.icon"/>
                          <img style="width: 2.4rem;height: 2.4rem;" v-else :src="item.defalutIcon"/>
                          <span style="margin-left: 10px;">{{ item.name }}</span>
                      </div>

                      <div>
                        <i style="min-width: 18px;" class="el-icon-arrow-right"></i>
                      </div>
                  </div>

                  <div v-else class="menu_box"  :class="keyPath==item.path?'bgfff':''">
                      <img style="width: 2.4rem;height: 2.4rem;" v-if="keyPath==item.path" :src="item.icon"/>
                      <img style="width: 2.4rem;height: 2.4rem;" v-else :src="item.defalutIcon"/>
                      <span class="text">{{ item.name }}</span>
                  </div>
              </el-menu-item>
            </el-menu>
        </el-aside>

        <el-container>
            <el-header height="8rem">
                <bear-header />
            </el-header>

            <el-main>
                <bear_content ref="contBox">
                    <router-view :bear-ref="$refs.contBox" />
                </bear_content>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>

import { getUser } from '@/api/auth'
import info from '@/views/components/logoinfo.vue'
import bearHeader from './Header.vue'
import Footer from './Footer.vue'
import bear_content from '@/views/components/bear_content.vue'

export default {
  components:{
    info,
    bearHeader,
    Footer,
    bear_content
  },
  name: 'layout-page',
  data () {
    return {
      avatar: '',
      name: '',
      keyPath:'',
      menuType:true,
      menuList:[
        {
          name:'首页',
          defalutIcon:require('@/assets/menu/home.png'),
          icon:require('@/assets/menu/home_1.png'),
          path:'/index'
        },
        {
          name:'教学练习',
          defalutIcon:require('@/assets/menu/work.png'),
          icon:require('@/assets/menu/work_1.png'),
          path:'/work/studytype'
        },
        {
          name:'销课记录',
          defalutIcon:require('@/assets/menu/sele.png'),
          icon:require('@/assets/menu/sele_1.png'),
          path:'/sele'
        },
        {
          name:'学员信息',
          defalutIcon:require('@/assets/menu/stud.png'),
          icon:require('@/assets/menu/stud_1.png'),
          path:'/student/list'
        },
        {
          name:'词汇测试',
          defalutIcon:require('@/assets/menu/test.png'),
          icon:require('@/assets/menu/test_1.png'),
          path:'/vocabulary/exam'
        },
        {
          name:'个人中心',
          defalutIcon:require('@/assets/menu/myzoe.png'),
          icon:require('@/assets/menu/myzoe_1.png'),
          path:'/myzoe'
        }
      ]
    }
  },
  computed:{
    menuSate(){
        return this.$store.state.user.menuState
    },
    Screen(){
        return this.$store.state.user.isFull
    },
    fullState(){
        return this.$store.state.user.fullState
    }

  },
  watch:{
    menuSate(newObj,oldObj){
      if(!newObj){
        this.menuType=false
      }
    },
    Screen(newObj,oldObj){
        if(newObj){
            // this.toggleFullScreen()
            // this.$refs.contBox.toggleFullScreen()
        }
    },
    
    $route: {
      handler: function(val, oldVal){
        // console.log('handler',val,oldVal);
        // this.keyPath=val.path
        if(this.Screen){
          // this.$refs.contBox.toggleFullScreen()
        }
      },
      deep: true
   }
  },
  created () {
    this.initData()
  },
  mounted(){
  },
  methods: {
    pullFn(){
      this.$store.commit('user/setFull',true)
      this.$store.commit('user/setfullState',0)

      // this.$refs.contBox.toggleFullScreen()
    },
    async initData () {
      const { data } = await getUser()
      this.$store.commit('user/setUserInfo', data)
      this.avatar = data.avatar
      this.name = data.name

      if (data.teachExercise && data.teachExercise.value == 0) {
          this.menuList = [
              {
                name:'首页',
                defalutIcon:require('@/assets/menu/home.png'),
                icon:require('@/assets/menu/home_1.png'),
                path:'/index'
              },
              {
                name:'词汇测试',
                defalutIcon:require('@/assets/menu/test.png'),
                icon:require('@/assets/menu/test_1.png'),
                path:'/vocabulary/exam'
              },
              {
                name:'销课记录',
                defalutIcon:require('@/assets/menu/sele.png'),
                icon:require('@/assets/menu/sele_1.png'),
                path:'/sele'
              },
              {
                name:'学员信息',
                defalutIcon:require('@/assets/menu/stud.png'),
                icon:require('@/assets/menu/stud_1.png'),
                path:'/student/list'
              },
              {
                name:'个人中心',
                defalutIcon:require('@/assets/menu/myzoe.png'),
                icon:require('@/assets/menu/myzoe_1.png'),
                path:'/myzoe'
              }
          ]
      }

    },
    handleConfirm () {
      this.$store.commit('user/logout')
      this.$router.push('/login')
    },
    handleSelect(key, keyPath) {
      if(key){
        this.keyPath=key
        this.$store.commit('user/setfullState',0)
      }
    },
    foldFn(){
      this.menuType=!this.menuType
      this.$store.commit('user/setmenu',this.menuType)
    }
  }
}
</script>

<style lang="scss" scoped>

.layout-page {
  height: 100%;
  background-image: url(@/assets/img/bj.png);
  background-size: 100% 100%;
  .el-aside {
    background: #FFFFFF;
    padding: 3rem 2.4rem 0;
    position: relative;
    .pull_img{
      position: absolute;
      right: 0;
      top: 50%;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .el-menu {
      border-right: none;
      margin-top: 1rem;
      &-item {
        background-color: transparent !important;
        padding: 0;
        margin-top: 1rem;
        font-size: 1.7rem;
        padding-left: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 0 !important;
        // max-height:9rem;
        height: auto;
        // min-height: 7rem;
        > span, i {
          padding-left: .5rem;
        }
        
      }
    }
  }
  .path_cont{
    width: 100%;
    height: 100%;
    min-height: 7rem;
    line-height: 8rem;
    border-radius: 1rem;
    padding: 0 1.5rem;
    color:#4A4A4A;
    &:hover{
      background: rgba($color:#0B9AFF , $alpha: .1) ;
      color: #0B9AFF;
    }
  }
  .el-main{
    padding:0;
  }
  .el-header {
    box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 999;
    padding: 0;
    min-height: 8rem;
    .user {
      display: flex;
      align-items: center;
      background: #fafbfd;
      height: 6rem;
      border: 1px solid #f1f3fa;
      padding: 0 1.5rem;
      .el-avatar {
        margin-right: 1.5rem;
      }
    }
    .logout {
      font-size: 2rem;
      color: #999;
      cursor: pointer;
      padding: 0 1.5rem;
    }
  }

}
.fold_box{
  width: 100%;
  height: 100%;

  // margin: auto;
  img{
    margin: auto;
  }
}
.menu_box{
  width: 100px;
  min-width: 100px;
  height: 100%;
  min-height: 9.5rem;
  border-radius: 20px;
  background: #EEF2F9;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color:#4A4A4A;
  box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.3),inset 0px 2px 2px 0px #E9ECF1;
  img{
    display: block;
    // margin: 0 auto;
  }
  .text{
    display: inline-block;
    font-size: 1.6rem;
    line-height: 2.1rem;
    margin-top: 10px;
  }
  &:hover{
    background: #FFFFFF;
    box-shadow: 0px 2px 2px 0px #E9ECF1;
    color: #0B9AFF;
    
  }
}
.bgfff{
  background: #FFFFFF;
  box-shadow: 0px 2px 2px 0px #E9ECF1;
  color: #0B9AFF;

}
.pathbj{
  background: rgba($color:#0B9AFF , $alpha: .1) ;
  color: #0B9AFF !important;
}
</style>
