<template>
  <common>
        <div class="ve_container">
            <div class="logo_cont">
                <div class="logo_cont_left flex_center_center">
                    <img class="logo_img" src="@/assets/img/logo_now.png" />
                    <h5>语言提升</h5>
                    <p>就来途正智能英语</p>
                </div>

                <div class="logo_cont_right flex_start_center">
                    <div class="tab_cont flex_center">
                        <el-tabs v-model="activeName" >
                            <el-tab-pane label="密码登录" name="0" />
                            <el-tab-pane label="验证码登录" name="1" />
                        </el-tabs>
                    </div>

                    <el-form ref="myForm" :model="form" :rules="rules">
                        <el-form-item label="手机号：" prop="mobile">
                            <el-input v-model="form.mobile" placeholder="请输入手机号" :maxlength="11" type="number" oninput="value.length > 11 && (value = value.slice(0, 11))" />
                        </el-form-item>

                        <el-form-item label="密码：" prop="password" v-if="activeName==0">
                            <el-input type="password" v-model="form.password" placeholder="请输入密码：" />
                        </el-form-item>
                      <el-form-item label="验证码：" prop="code" class="cur" v-else>
                        <el-input  v-model="form.code" placeholder="请输入验证码：" >
                          <template slot="append">
                          <div @click="sendSmsCode(4)">{{canSendSms ? '发送验证码' : countDown +'s后重新发送'}}</div>
                          </template>
                        </el-input>
                      </el-form-item>

                      <div class="row_deal">
                          <el-checkbox v-model="checked">已阅读并同意</el-checkbox>
                          <span class="user cur" @click.stop="showAgreementFun(1)">《用户协议》</span>
                          和
                          <span class="privacy cur" @click.stop="showAgreementFun(2)">《隐私协议》</span>
                          <span class="nopass cur" @click="forgetPassword" style="color: #0B9AFF;">忘记密码？</span>
                      </div>

                      <el-form-item class="tc">
                          <el-button style="width: 164px;margin-bottom: 20px;" type="primary" @click="clickLogin">登录</el-button>
                          <!-- <div class="cur">我是机构，<span class="texet" @click="regFn">机构注册</span> </div> -->
                      </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>

        <!-- 忘记密码弹窗 -->
        <bear_dialogVue
          ref="forgetPasswordDialog"
          title="忘记密码"
          :width="'40%'"
          :height="'60vh'"
          :closeCallBack="smsCodeChangePass"
          >
          <div class="pass_cont">
              <p class="text">验证您的账户并重置密码登录</p>
              <p class="text1"></p>

                  <div class="flex_center">
                      <img src="@/assets/img/4.png" alt="">
                      <el-input placeholder="请输入手机号" v-model="forgetForm.mobile" />
                  </div>

                  <div class="flex_center">
                      <img src="@/assets/img/5.png" alt="">
                      <el-input placeholder="请输入验证码" v-model="forgetForm.code" class="cur">
                          <template slot="append">
                            <div @click="sendSmsCode(2)">{{canForgetSms ? '发送验证码' : forgetCountDown +'s后重新发送'}}</div>
                          </template>
                      </el-input>
                  </div>

                <div class="flex_center">
                    <img src="@/assets/img/lock.png" alt="">
                    <el-input placeholder="请输入新密码" v-model="forgetForm.password" show-password />
                </div>

                <div class="flex_center">
                    <img src="@/assets/img/lock.png" alt="">
                    <el-input placeholder="请确认新密码" v-model="forgetForm.confirmPassword" show-password />
                </div>
          </div>
      </bear_dialogVue>

      <!-- 协议 -->
      <bear_dialogVue
          ref="agreementDialog"
          :title="agreementTitle"
          :width="'40%'"
          :height="'80vh'"
          :isShowCancel="false"
          :isShowConfirm="false"
          >
          <div class="pass_cont">
              <div v-html="agreementConfig.serviceAgreement" v-if="showAgreement == 1"></div>
              <div v-html="agreementConfig.privacyAgreement" v-else></div>
          </div>
      </bear_dialogVue>
    </common>
</template>

<script>
import common from '@/views/components/Common'
import bear_dialogVue from '@/views/components/bear_dialog.vue'
import { login, loginSmsCode, smsCode, changePasswordBySms, getUser} from '@/api/auth.js'
import { config } from '@/api/config.js'
import { checkMobile } from '@/utils/validate'
export default {
  name: 'login-page',
  components: {
    common,
    bear_dialogVue
  },
  data () {
    return {
      form: {
        mobile: '',
        password: '',
        code: ''
      },
      forgetForm: {
        mobile: '',
        password: '',
        code: '',
        confirmPassword: ''
      },
      activeName: '0', // 0 密码登录 1 验证码登录
      checked: false,
      rules: {
        mobile: [
          { required: true, validator: checkMobile }
        ],
        password: [
          { required: true, message: '请输入密码' },
          { min: 6, max: 15, message: '密码为6-15位', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: ['blur', 'change'] },
          { min: 6, max: 6, message: '长度必须是6位', trigger: 'change' }
        ]
      },
      timer: null,
			countDown: 60,
			canSendSms: true,
      forgetTimer: null,
      forgetCountDown: 60,
      canForgetSms: true,
      agreementTitle: '用户协议',
      showAgreement: 0, // 0 不显示 1 显示用户协议 2 显示隐私协议
      agreementConfig: {
        serviceAgreement: '',
        privacyAgreement: ''
      }
    }
  },
  mounted() {
      let mobile = this.$route.query.mobile
      if (mobile) {
          this.form.mobile = mobile
      }
      this.getAgreementConfig()
  },
  methods: {
    getAgreementConfig() {
        config({
          key: ['service_agreement', 'privacy_agreement']
        }).then(res => {
            this.agreementConfig.serviceAgreement = res.data.service_agreement
            this.agreementConfig.privacyAgreement = res.data.privacy_agreement
        })
    },
    //忘记密码修改
    async smsCodeChangePass(i) {
        if(i== 2){
          return
        }else if (i == 1) {
            if (this.forgetForm.mobile == '') {
                this.$message.error('请输入手机号')
                return false
            }
            let myreg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
            if (!myreg.test(this.forgetForm.mobile)) {
                this.$message.error('请输入正确的手机号')
                return false
            }
            if (this.forgetForm.code == '') {
                this.$message.error('请输入验证码')
                return false
            }
            if (this.forgetForm.password == '') {
                this.$message.error('请输入新密码')
                return false
            }
            if (this.forgetForm.confirmPassword == '') {
                this.$message.error('请确认新密码')
                return false
            }
            if (this.forgetForm.password != this.forgetForm.confirmPassword) {
                this.$message.error('两次密码不一致')
                return false
            }
            await changePasswordBySms({
                mobile: this.forgetForm.mobile,
                code: this.forgetForm.code,
                password: this.forgetForm.password
            }).then(res => {
                if (res.error == 0) {
                    this.$message.success('修改密码成功')
                    this.form.mobile = this.forgetForm.mobile
                    this.resetForgetForm()
                } 
            })
        }
    },
    async clickLogin () {
      await this.$refs.myForm.validate(async (valid) => {
        if (!valid) {
          return false
        }

        if (!this.checked) {
          this.$message.error('请先阅读并同意《用户协议》和《隐私协议》')
          return false
        }

        if (this.activeName == 1) {
          //验证码登录
          await loginSmsCode({
            mobile: this.form.mobile,
            code: this.form.code
          }).then(res => {
              if (res.error == 0) {
                this.$store.commit('user/setUserToken', res.data.accessToken)

                getUser().then(res => {
                  if (res.error == 0) {
                    this.$store.commit('user/setUserInfo', res.data)
                  }
                })
                localStorage.setItem('tips',1)
                this.$router.push('/index')
              } 
          })
        } else {
          await login(this.form).then(res => {
            if (res.error == 0) {
              this.$store.commit('user/setUserToken', res.data.accessToken)

              getUser().then(res => {
                if (res.error == 0) {
                  this.$store.commit('user/setUserInfo', res.data)
                }
              })
              localStorage.setItem('tips',1)
              this.$router.push('/index')
            } 
          })
        }
      })
    },
    sendSmsCode(type) {
      let param = {}
      if (type == 4) {
        if (!this.canSendSms) {
          return false
        }

        if (!this.form.mobile) {
            this.$message.error('请输入手机号')
            return false
        }

        param = {
          mobile: this.form.mobile,
          type: type
        }

      }else if (type == 2) {
          if (!this.canForgetSms) {
            return false
          }
          if (!this.forgetForm.mobile) {
            this.$message.error('请输入手机号')
            return false
          }
          param = {
            mobile: this.forgetForm.mobile,
            type: type
          }
      }
      smsCode(param).then(res => {
            if (res.error == 0) {
              this.$message.success('发送成功')
              if (type == 4) {
                this.canSendSms = false
                this.timer = setInterval(()=>{
                  this.countDown = this.countDown - 1
                  if(!this.countDown){
                      clearInterval(this.timer)
                      this.canSendSms = true
                      this.countDown = 60
                  }
                },1000)
              }else if (type == 2) {
                this.canForgetSms = false
                this.timer = setInterval(()=>{
                  this.forgetCountDown = this.forgetCountDown - 1
                  if(!this.forgetCountDown){
                      clearInterval(this.timer)
                      this.canForgetSms = true
                      this.forgetCountDown = 60
                  }
                },1000)
              }
              
            }
        })
    },
    resetForgetForm() {
        this.forgetForm = {
          mobile: '',
          password: '',
          code: '',
          confirmPassword: ''
        }
    },
    resetForm() {
        this.form = {
          mobile: '',
          password: '',
          code: ''
        }
    },
    showAgreementFun(val) {
      this.showAgreement = val
      if (val == 1) {
          this.agreementTitle = '用户协议'
      }else{
          this.agreementTitle = '隐私协议'
      }
      this.$refs.agreementDialog.openDialog()
    },
    forgetPassword() {
        this.$refs.forgetPasswordDialog.openDialog()
    },
    regFn () {
      this.$router.push('/register')
    }

  }

}
</script>

<style lang="scss" scoped>
@import "~@/styles/mixins.scss";
::v-deep .el-checkbox__label{padding-left: 5px}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label{color: #606266}

.ve_container {
    position: absolute;
    z-index: 1;
    width: 1000px;
    height: 70.2rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 1s;
    min-height: 27.3rem;
    text-align: center;
    border-radius: 20px;
    overflow: hidden;
    opacity: 0.88;
    background: #ffffff;
    backdrop-filter: blur(16px);
    box-shadow: 0px 18px 25px 0px #bbddf3;
    .logo_cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        &_left {
            width: 500px;
            height: 100%;
            background: #F4F8FF;
            h5{
              font-size: 3rem;
              margin: 1.6rem 0 1.1rem;
            }
            p{
              font-size: 1.5rem;
              color: #767676;
            }
            .logo_img{
              width: 28.8rem;
              height: 26.1rem;
            }
        }
        &_right{
          width: 500px;
          height: 100%;
          padding-left: 5.9rem;
          .tab_cont{
            display: flex;
          }
        }
    }
}
.row_deal{
  margin-bottom: 8.2rem;
  font-size: 1.2rem;
  color: #4A4A4A;
  .user{
    color: #0B9AFF
  }
  .privacy{
    color: #0B9AFF
  }
  .nopass{
    color: #0B9AFF;
    margin-left: 3rem;
  }
}
.login-page {
  min-height: 100vh;
  background: url(@/assets/login-bg.svg) no-repeat center / cover;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .el-card {
    width: 420px;
    ::v-deep .el-card__header{
      height: 80px;
      background: rgba(114,124,245,1);
      text-align: center;
      line-height: 40px;
      color: #fff;
      font-size: 18px;
    }
  }
  .el-form {
    padding: 0 20px;
  }
  .tc {
    text-align: center;
  }
}
.texet{
  color:$theme-color;
}

.pass_cont{
    padding: 4rem;
    .flex_center{
        margin-bottom: 1.6rem;
        img{
            margin-right: 16px;
        }
    }
    .text{
        font-size: 2rem;
        font-family: MicrosoftYaHei;
        color: #1A1A1A;
    }
    .text1{
        font-family: MicrosoftYaHei;
        font-size: 1.6rem;
        font-weight: normal;
        color: #3A3F5E;
        margin: 2rem 0 3.8rem;
    }
}
</style>
