/* 封装axios用于发送请求 */
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import { Message } from 'element-ui'

let baseURL = ''

if (process.env.NODE_ENV == "development") {
  //开发环境
  baseURL = 'teacher/'
} else if (process.env.NODE_ENV == "production") {
  //生产环境
  baseURL = '/api/teacher/'
}else{
  baseURL = '/api/teacher/'
}

// 创建一个新的axios实例
const request = axios.create({
  baseURL,
  timeout: 50000
})


// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  const token = store.state.user.token
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

let isshow = true

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 对响应数据做点什么

  if (response.data.error !== 0) {
    sessionStorage.setItem('change',false)

      if (response.data.error == 404) {
          Message.error('请求不存在')
      } else if (response.data.error == 500) {
        Message.error( response.data.message || '网络异常，请稍后再试')
      }else if (response.data.error == 401) {
        if (isshow) {
          Message.error('账号不存在或已被禁用')
          store.commit('user/logout')
          router.push('/login')
        }
        isshow = false
      }else if (response.data.error == 402) {
        if (isshow) {
            Message.error('登录失效，请重新登录')
            store.commit('user/logout')
            router.push('/login')
        }
        isshow = false
      }else if (response.data.error == 403) {
        if (isshow) {
            Message.error('无权限访问')
            store.commit('user/logout')
            router.push('/login')
        }
        isshow = false
      }else if (response.data.error == 406) {
        //不提示信息
        if (isshow) {
            Message.error('异地登录，强制下线')
            store.commit('user/logout')
            router.push('/login')
        }
        isshow = false
      } else if (response.data.error == 407) {
        //不提示信息
      } else {
        Message.error(response.data.message)
      }
  }

  return response.data
}, function (error) {
  // 对响应错误做点什么
  if (error.response.status == 500||error.response.status==502) {
      Message.error('网络异常，请稍后再试')
  }
  return Promise.reject(error)
})

export default request
